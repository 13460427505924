<template>
  <div class="campagin_google create_gg_ad">
    <div class="rt_ad_content">
      <!-- 系列 -->
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
      >
        <!-- 系列名称 -->
        <div class="content_gg budget_content">
          <el-form-item
            label="系列名称"
            prop="name"
          >
            <el-input
              v-model="ruleForm.name"
              maxlength="999999"
              v-limitWords="80"
              show-word-limit
              type="textarea"
              placeholder="请输入内容"
              @input="$maxLength('name', 80)"
              @change="f_input"
            ></el-input>
          </el-form-item>
          <div class="flex">
            <el-form-item
              label="系列类型"
              prop="advertising_channel_type"
            >
              <el-select
                v-model="ruleForm.advertising_channel_type"
                disabled
                placeholder="请选择"
                @change="f_input"
              >
                <el-option
                  v-for="item in adTypeData"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="状态"
              prop="status"
              style="margin-left: 20px"
              v-if="1 != 1"
              @change="f_input"
            >
              <el-switch v-model="ruleForm.status"></el-switch>
            </el-form-item>
          </div>
        </div>
        <!-- 3 投放区域-->
        <div
          class="title_part"
          id="id6"
        >
          投放区域
        </div>
        <div class="content_gg target_ad campagin_target">
          <el-form-item
            label="地区"
            prop="region"
          >
            <el-select
              v-model="ruleForm.region"
              filterable
              placeholder="请选择"
              @change="f_getCountry"
              multiple
            >
              <el-option
                v-for="(item, index) in constDataGoogle.defaultCountries"
                :key="item.criteria_id + item.name_zhcn + index"
                :label="item.name_zhcn"
                :value="item.criteria_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="语言"
            prop="language"
          >
            <el-select
              v-model="ruleForm.language"
              multiple
              filterable
              placeholder="请选择"
              @change="f_getLanguage"
            >
              <el-option
                v-for="(item, index) in constDataGoogle.defaultLanguages"
                :key="item.id + item.nameZhcn + index"
                :label="item.nameZhcn"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- 6 出价预算-->
        <div
          class="title_part"
          id="id9"
        >
          投放预算
        </div>
        <div class="budget_content">
          <!-- 广告系列预算 -->
          <div class="bud_tit">广告系列预算</div>
          <div class="bud_subtit">请为广告系列设置预算，google 将自动管理预算再各个广告组间分配</div>
          <el-radio-group
            v-model="ruleForm.daily_budget_radio"
            @change="f_input"
          >
            <div class="budget_raido">
              <el-radio :label="1">日预算</el-radio>
              <el-input
                placeholder="请输入内容"
                type="number"
                :step="0.01"
                :precision="2"
                @blur="f_blurBudget(ruleForm.daily_budget, 'daily_budget')"
                v-model.number="ruleForm.daily_budget"
                :min="0.01"
                @change="f_input"
              >
                <template slot="append">美元</template>
              </el-input>
            </div>
          </el-radio-group>
          <!-- 设置目标系列费用 -->
          <div class="bud_tar">
            <span>设置目标系列费用</span>
            <el-switch
              v-model="ruleForm.bidding_strategy_type"
              active-value="TARGET_CPA"
              @change="f_changeBiddingStrategyType"
              inactive-value="MAXIMIZE_CONVERSIONS"
            >
            </el-switch>
          </div>
          <!-- 广告组出价 -->
          <div v-if="ruleForm.bidding_strategy_type == 'TARGET_CPA'">
            <div class="bud_tit bud_tit_ad">广告组出价</div>
            <div class="bud_subtit">请为广告组设置出价，计费模式默认为：每次转化出多少钱</div>
            <div class="budget_raido">
              <el-select
                v-model="ruleForm.target_cpa_radio"
                placeholder="请选择"
                @change="f_input"
              >
                <el-option
                  v-for="item in targetCpData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                placeholder="请输入内容"
                type="number"
                :step="0.01"
                :precision="2"
                @blur="f_blurBudget(ruleForm.target_cpa, 'target_cpa')"
                v-model.number="ruleForm.target_cpa"
                :min="0.01"
                @change="f_input"
              >
                <template slot="append">美元/次</template>
              </el-input>
            </div>
          </div>
        </div>
        <!-- 7 投放时间-->
        <div
          class="title_part tit_heigth"
          id="id10"
        >
          投放时间
        </div>
        <div class="content_gg">
          <el-form-item
            label="投放时段"
            prop="start_date"
          >
            <el-date-picker
              v-model="ruleForm.start_date"
              value-format="yyyy-MM-dd"
              type="date"
              @change="f_input"
              placeholder="选择开始日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <el-date-picker
              style="margin-left: 10px"
              v-model="ruleForm.end_date"
              value-format="yyyy-MM-dd"
              type="date"
              @change="f_input"
              placeholder="选择结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';
import moment from 'moment';
export default {
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isPass: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ruleForm: {
        region: [],
        language: '',
        advertising_channel_type: 'DISCOVERY',
        start_date: moment(new Date()).format('YYYY-MM-DD'),
        end_date: '',
        target_cpa_radio: '1',
        daily_budget: 30.0, //日预算
        daily_budget_radio: 1,
        target_cpa: 30.0, //广告组出价
        bidding_strategy_type: 'MAXIMIZE_CONVERSIONS',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 3600 * 1000 * 24 < Date.now();
        },
      },
      rules: {
        name: [{ required: true, message: '请输入系列名称', trigger: ['blur', 'change'] }],
      },
    };
  },
  computed: {
    ...mapState({
      constDataGoogle: (state) => state.initData.constDataGoogle,
      adTypeData: (state) => state.initData.adTypeData,
      targetCpData: (state) => state.initData.targetCpData,
    }),
    thirdData() {
      return this.value;
    },
  },
  watch: {
    thirdData: {
      handler(newV) {
        this.f_initCampagin(newV);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    //   设置目标系列
    f_changeBiddingStrategyType(v) {
      if (this.ruleForm.bidding_strategy_type == 'TARGET_CPA') {
        this.ruleForm.target_cpa = 30.0;
      } else this.ruleForm.target_cpa = null;
      this.f_input();
    },
    // 初始化表单数据
    f_initCampagin(newV) {
      let data = { ...newV };
      // 单独处理地区和语言
      let region = [],
        language = [];
      if (data.campaign_criterias.length > 0) {
        data.campaign_criterias.map((item) => {
          if (item.type == 'LOCATION') region.push(item.criterion_id);
          if (item.type == 'LANGUAGE') language.push(item.criterion_id);
        });
      }
      data.region = region;
      data.language = [...language];
      this.ruleForm = { ...data, target_cpa_radio: '1', daily_budget_radio: 1 };
      console.log(this.ruleForm, '系类');
    },
    //   选中国家 ---默认处理语言和行动号召力
    f_getCountry(val) {
      let language = [];
      if (this.constDataGoogle && this.constDataGoogle.defaultCountries.length > 0) {
        this.constDataGoogle.defaultCountries.map((countrie) => {
          if (val.includes(countrie.criteria_id)) {
            language.push(...countrie.defaultLanguageIds);
          }
        });

        this.ruleForm.language = Array.from(new Set(language));

        language = [];
        // 处理选择的语言
        if (this.ruleForm.language.length > 0)
          this.ruleForm.language.map((item) => {
            let obj = { type: 'LANGUAGE', criterion_id: '' };
            obj.criterion_id = item;
            language.push(obj);
          });
      }
      // 处理选择的地区格式
      let region = [];
      if (this.ruleForm.region && this.ruleForm.region.length > 0) {
        this.ruleForm.region.forEach((item) => {
          region.push({ type: 'LOCATION', criterion_id: item });
        });
      }
      this.ruleForm.campaign_criterias = [...language, ...region];
      this.f_input();
    },
    // 选中语言
    f_getLanguage(val) {
      console.log(val, '语言');
      let region = this.ruleForm.campaign_criterias.filter((item) => item.type == 'LOCATION'),
        language = [];
      if (val.length > 0)
        val.map((item) => {
          let obj = { type: 'LANGUAGE', criterion_id: '' };
          obj.criterion_id = item;
          language.push(obj);
        });
      this.ruleForm.campaign_criterias = [];
      this.ruleForm.campaign_criterias = [...language, ...region];
      console.log(this.ruleForm);
      this.f_input();
    },
    //   预算的最小值
    f_blurBudget(val, name) {
      if (val < 0.01) this.ruleForm[name] = 0.01;
      console.log(this.ruleForm);
      this.f_input();
    },
    // 更新系列值为双向绑定的值
    f_input() {
      this.$emit('input', cloneDeep(this.ruleForm));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/google/googleCreate.scss';
</style>
