<template>
    <div class="campain_tree">
        <!-- 这是左侧系列树 -->
        <el-input
            placeholder="输入关键字进行过滤"
            suffix-icon="el-icon-search"
            size="small"
            v-model="filterText">
            </el-input>
        <div class="lf_tree">
            <el-tree
            class="filter-tree"
            :data="detailsData"
            auto-expand-parent
            show-checkbox
            node-key="id"
            :check-on-click-node="true"
            :props="defaultProps"
            :check-strictly="true"
            :default-expanded-keys="[CurrentCheckedKeys.id]"
            :filter-node-method="filterNode"
            @check-change="f_handleCheckChange"
            ref="tree">
            </el-tree>
        </div>
    </div>
</template>

<script>
export default {
    components:{},
    props:{
        detailsData:{//详情数据
            type:Array,
            default:()=>[]
        },
        defaultCurrentCheckedKeys:{//当前选中的树节点
            type:Object,
            default:()=>{}
        },
        CurrentCheckedKeys:{//当前选中的树节点
            type:Object,
            default:()=>{}
        },
    },
    data(){
        return {
            filterText: '',
            data: [],
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            selectedNodesData:[],//选中的树节点
        }
    },
    computed:{},
    watch:{
        filterText(val) {
            this.$refs.tree.filter(val);
        },
        defaultCurrentCheckedKeys:{
            handler(newV){
                if(newV) {
                    this.f_handleCheckChange(newV,true,'indeterminate')
                    if(this.defaultCurrentCheckedKeys&&this.defaultCurrentCheckedKeys.id) this.f_setKeys([this.defaultCurrentCheckedKeys.id])
                }
            },
            immediate:true,
            deep:true
        }
    },
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 设置当前选中的树节点
        f_setKeys(arr){
            this.selectedNodesData = []
            this.$refs.tree.setCheckedKeys(arr)
        },
        // 清空选中数据
        f_empty(){
            this.selectedNodesData = []
            this.f_setKeys([])
        },
        // 删除树节点
        f_del(item){
            this.f_handleCheckChange(item,false,false)
            this.$refs.tree.setChecked(item,false,false)
        },
        // 选中树
        f_handleCheckChange(data, checked, indeterminate){
            if(checked == true) this.f_selectedCheckBox(data)
            if(this.selectedNodesData.length==0){
                this.f_selectedCheckBox(data)
            }else if(this.selectedNodesData[0].id == data.id){
                this.f_selectedCheckBox(data)
            }
            this.$emit('currentNode',data)
            console.log(data,this.selectedNodesData,"选中的节点----");
        },
        // 选中当前元素
        f_selectedCheckBox(data){
            setTimeout(() => {
                this.f_setKeys([data.id])
                this.selectedNodesData.push(data)
            });
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        f_cancel(data){
            this.$emit('close',data)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.campain_tree{
    // border-right: 1px solid $bgColorDark;
    height: calc(100%);
    padding: 10px 20px 10px 0;
    ::v-deep.el-input{
        padding-bottom: 10px;
        .el-input__inner{
            width: 99%;
            border-radius: 50px;
        }
        .el-input__suffix{
            right: 15px;
            top: -3px;
        }
    }
    .lf_tree{
        overflow: hidden;
        overflow-y: auto;
        max-height: 630px;
    }
    ::v-deep.el-tree-node__label{
        width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>