// 系列校验
export function campaginValid(data){
    let obj = {msg:'',isPass:true}
    if(!data.name){//名字
        obj.msg = '系列名字不能为空！';
        obj.isPass = false
        return obj
    }
    if(data.campaign_criterias.length>0){//地区和语言
        let language = data.campaign_criterias.filter(item=>item.type=="LANGUAGE")
        let location = data.campaign_criterias.filter(item=>item.type=="LOCATION")
        if(language.length==0){
            obj.msg = '语言不能为空！';
            obj.isPass = false
        }
        if(location.length==0){
            obj.msg = '地区不能为空！';
            obj.isPass = false
        }
        return obj
    }
    if(!data.start_date){//日期
        obj.msg = '投放的开始日期不能为空！';
        obj.isPass = false
        return obj
    }
    return obj
}
// 广告组校验
export function adGroupsValid(data){
    let obj = {msg:'',isPass:true}
    if(!data.name){//名字
        obj.msg = '广告组名字不能为空！';
        obj.isPass = false
        return obj
    }
    return obj
}
// 发现广告校验
export function foundValid(data){
    let obj = {msg:'',isPass:true},discoveryInfo = data.discovery_multi_asset_ad_info
    // if(!data.name){//名字
    //     obj.msg = '广告名字不能为空！';
    //     obj.isPass = false
    //     return obj
    // }
    if(!data.landing_url){//推广链接
        obj.msg = '推广链接不能为空！';
        obj.isPass = false
        return obj
    }
    if(!discoveryInfo.business_name){//名字
        obj.msg = '商家名字不能为空！';
        obj.isPass = false
        return obj
    }
    if(!discoveryInfo.call_to_action_text){//名字
        obj.msg = '行动号召力不能为空！';
        obj.isPass = false
        return obj
    }
    let headlines = discoveryInfo.headlines.map(item=>item.text)
    let descriptions = discoveryInfo.descriptions.map(item=>item.text)
    let num = -1
    headlines.map(v=>{
        if(v) num++
    })
    if(num == -1) {
        obj.msg = "标题至少有一条！"
        obj.isPass = false
        return obj
    }
    let k = -1
    descriptions.map(v=>{
        if(v) k++
    })
    if(k == -1) {
        obj.msg = "描述至少有一条！"
        obj.isPass = false
        return obj
    }
    return obj
}
