<template>
    <div class="bulk">
        <!-- 批量编辑 -->
        <el-row>
            <el-col :span="6">
                <div class="select_trees_node">
                    <campagin-tree :detailsData="detailsData" :CurrentCheckedKeys="CurrentCheckedKeys" :defaultCurrentCheckedKeys="defaultCurrentCheckedKeys" @currentNode="f_currentNode"></campagin-tree>
                </div>
            </el-col>
            <el-col :span="18">
                <div class="edit_campagin_content">
                    <campagin v-show="CurrentCheckedKeys.level == 'campaign'"  v-model="campaginData[commonIndex]"  @input="f_inputCampagin"></campagin>
                    <adgroup v-show="CurrentCheckedKeys.level == 'adset'"   v-model="adsetsData[commonIndex]"  @input="f_inputCampagin"></adgroup>
                    <ads v-show="CurrentCheckedKeys.level == 'ad'" :accountId="accountId" v-model="adsData[commonIndex]" :campaignData="campaginData[commonIndex]" @input="f_inputCampagin"></ads>
                </div>
            </el-col>
        </el-row>
        <el-row class="bulk_footer">
            <el-button @click="f_cancel">取 消</el-button>
            <el-button type="primary" @click="f_sure">确 定</el-button>
        </el-row>
    </div>
</template>

<script>
import {campaginValid,adGroupsValid,foundValid} from '../valid'
import campaginTree from '@/views/google/bulkCopy/components/campaginTree/index.vue'
import campagin from '@/views/google/bulkCopy/components/campagin/index.vue'
import adgroup from '@/views/google/bulkCopy/components/adgroup/index.vue'
import ads from '@/views/google/bulkCopy/components/ads/index.vue'
import {mapState} from 'vuex'
import {draftCampaignDetail,saveDraftCampaign,createDraftCampaign} from '@/api/google/ceateAdGg.js';
import { cloneDeep } from 'lodash'
export default {
    components:{campaginTree,campagin,adgroup,ads},
    props:{
        editType:{//批量复制copy 还是草稿编辑edit  
            type:String,
            default:'edit'
        },
        accountId: {
            type: [String, Array],
            default: [],
        },
    },
    data(){
        return {
            commonIndex:0,//当前选中节点的下表
            detailsData:[],//所有数据
            campaginData:[],//所有系列
            adsetsData:[],//所有广告组
            adsData:[],//所有广告
            defaultCurrentCheckedKeys:{},//当前默认的树节点id
            CurrentCheckedKeys:{},//当前选中的树节点id
            CurrentCheckedKeysThird:{},//用来做监听
        }
    },
    computed:{
        ...mapState({
            googleValid: state => state.initData.googleValid,
        }),
    },
    watch:{
        CurrentCheckedKeysThird:{
            handler(newV,oldV){
                this.f_handleData(oldV)
            },
            immediate:true,
            deep:true
        }
    },
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 确定
        async f_sure(){
            console.log('1111111111')
            // 校验广告系列
            let camp = true,title = '',num = -1
            for(var i=0;i<this.campaginData.length;i++){
                if(!campaginValid(this.campaginData[i]).isPass){
                    camp = false
                    title = campaginValid(this.campaginData[i]).msg
                    num = i
                    break
                }
            }
            // 校验广告组
            for(var i=0;i<this.adsetsData.length;i++){
                if(!adGroupsValid(this.adsetsData[i]).isPass){
                    camp = false
                    title = adGroupsValid(this.adsetsData[i]).msg
                    num = i
                    break
                }
            }
            // 校验发现广告
            for(var i=0;i<this.adsData.length;i++){
                if(!foundValid(this.adsData[i]).isPass){
                    camp = false
                    title = foundValid(this.adsData[i]).msg
                    num = i
                    break
                }
            }
            if(!camp) return this.$message.warning(`${title}`)
            // 创建保存---------------------------------------------------------------------------start
            console.log('222222',this.detailsData,)
            let res = {}
            this.$showLoading()
            if(this.editType == 'edit') res = await saveDraftCampaign(this.detailsData[0]) //批量编辑草稿箱
            else res = await createDraftCampaign(this.detailsData[0]) //批量复制
            this.$hideLoading()
            if(res.code == 0){
                this.$message.success("保存成功！")
                this.$emit("close")
            }
            // 创建保存---------------------------------------------------------------------------end
        },
        // 处理数据改变
        f_handleData(oldV){
            this.CurrentCheckedKeys = cloneDeep(oldV)
            if(oldV.level == 'campaign'){//系列
                this.campaginData.map((item,index)=>{
                    if(item.id == oldV.id) this.commonIndex = index
                })
            }else if(oldV.level == 'adset'){//广告组
                this.adsetsData.map((item,index)=>{
                    if(item.id == oldV.id) this.commonIndex = index
                })
            }else if(oldV.level == 'ad'){//广告
                this.adsData.map((item,index)=>{
                    if(item.id == oldV.id) this.commonIndex = index
                })
            }
        },
        // 改变系列的值
        f_inputCampagin(data){
            let adList = cloneDeep(this.adsData),adsetList = cloneDeep(this.adsetsData),campaginList = cloneDeep(this.campaginData)
            // 给每个广告组加上ads字段
            adsetList.map(v=>{
                v.ads = [];
                v.children= []
                adList.map(k=>{
                    if(v.id == k.parentId){
                        v.ads.push(k)
                        v.children.push(k)
                    }
                });
            })
            // 把广告组放进广告系列列表
            campaginList.map(v=>{
                v.adgroups = [];
                v.children = [];
                adsetList.map(m=>{
                    if(v.id == m.parentId){
                        v.adgroups.push(m)
                        v.children.push(m)
                    }
                })
            })
            this.detailsData = cloneDeep(campaginList)
            console.log(this.detailsData,"更新了----")
        },
        // 当前选中节点
        f_currentNode(data){
            this.CurrentCheckedKeysThird = cloneDeep(data)
        },
        // 生成虚拟id
        S4() {
            return Math.random().toString(36).slice(2)   
        },
        // 获取详情数据
        async f_getDraftCampaignDetail(id){
           let that = this
           this.$showLoading()
           let res = await draftCampaignDetail({draftId:id})
           this.$hideLoading()
           if(res.code == 0){
               that.f_handleBulkData(res.data)
           }
        },
        // 处理数据格式
        f_handleBulkData(v){
            console.log(v,"vvvvvvvvv")
            let data = [v],that = this
            if(data.length>0) data.map((v,index)=>{//id  name  index children
                v.children = v.adgroups;
                v.index = index
                that.campaginData.push(v)
                if(v.children.length>0) v.children.map((k,i)=>{
                    k.children = k.ads;
                    k.index = i
                    k.parentId = v.id
                    k.id = that.S4()
                    that.adsetsData.push(k)
                    if(k.children.length>0) k.children.map((g,j)=>{
                        g.index = j
                        g.id = that.S4()
                        g.parentId = k.id
                        that.adsData.push(g)
                    })
                })
            })
            this.detailsData = cloneDeep(data)
            console.log(that.campaginData,that.adsData,that.adsetsData,"深度克隆树----")
            this.defaultCurrentCheckedKeys = this.detailsData[0]
            this.CurrentCheckedKeys = this.detailsData[0]
        },
        // 取消
        f_cancel(){
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss">
::-webkit-scrollbar {
	width: 7px; /*滚动条宽度*/
	height: 8px; /*滚动条高度*/
}

::-webkit-scrollbar-track {
	border-radius: 3px; /*滚动条的背景区域的圆角*/
	background-color: #f2f2f2; /*滚动条的背景颜色*/
}
::-webkit-scrollbar-thumb {
	background: #999 !important;
	border-radius: 30px !important;
}
</style>
<style scoped lang="scss">
.bulk{
    min-height: 700px;
    position: relative;
    .bulk_footer{
        text-align:right;
        margin-top:10px;
        position: absolute;
        width: calc(100% + 40px);
        background-color: #fff;
        left: -20px;
        padding: 10px 20px 0 0;
        bottom: 0px;
    }
}
</style>